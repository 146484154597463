import { sanitizeString } from "@/helpers/string.helpers"
import { INEQUALITY_SYMBOL } from "@/organisms/SmartSearch/const/symbol.constants"

import type { FilterServiceNS } from "./FilterService.types"

export class FilterService {
  private __filters: FilterServiceNS.List

  constructor(filters: FilterServiceNS.List) {
    this.__filters = filters.sort((a, b) => a.label.localeCompare(b.label))
  }

  get filters() {
    return this.__filters
  }

  private set filters(filters: FilterServiceNS.List) {
    this.__filters = filters
  }

  public getFilterByKey(key: string) {
    return this.filters.find((filter) => filter.key === key)
  }

  public getFilterByLabel(label: string) {
    return this.filters.find((filter) => filter.label === label)
  }

  public getHelpersByLinkedKey(linkedKey: string) {
    return this.filters
      .filter((filter) => filter.linkedKey === linkedKey)
      .sort((a, b) => a.label.localeCompare(b.label))
  }

  public updateFiltersOptions(
    optionsRecord: Record<string, FilterServiceNS.Options[]>
  ) {
    this.filters = this.filters.map((filter) => {
      if (filter.type === "multiple" && optionsRecord[filter.key]) {
        return { ...filter, options: optionsRecord[filter.key] }
      }

      return filter
    })

    return this
  }

  public syncFiltersValueWithSearchParams(searchParams: URLSearchParams) {
    const getValue = (key: string) => {
      const value = searchParams.get(key)

      if (value) return sanitizeString(value)

      return value
    }

    this.filters = this.filters.map((filter) => {
      if (filter.type === "single") {
        let value = getValue(filter.key)

        value =
          filter.options?.find(
            ({ value: valueOption }) => valueOption === value
          )?.label || value

        return Object.assign(filter, { value })
      }

      if (filter.type === "multiple") {
        let value = getValue(filter.key)

        value =
          value
            ?.split(",")
            .map(
              (v: string) =>
                filter.options?.find(
                  ({ value: valueOption }) => valueOption === v
                )?.label
            )
            .filter(Boolean)
            .join(",") || value

        return Object.assign(filter, { value })
      }

      const value = getValue(filter.key)

      return Object.assign(filter, {
        value: /\s/g.test(value || "") ? `"${value}"` : value
      })
    })

    return this
  }

  public getFilterInOrder(
    values: (string | { label: string })[],
    fieldName: FilterServiceNS.KeysFilterName = "label"
  ) {
    const orderLabelsByIndex = values.reduce(
      (acc, value, index) => {
        const key = typeof value === "string" ? value : value.label

        acc[key] = index

        return acc
      },
      {} as Record<string, number>
    )

    return [...this.filters].sort((a, b) => {
      const aIndex = orderLabelsByIndex[a[fieldName]]
      const bIndex = orderLabelsByIndex[b[fieldName]]

      if (aIndex === undefined) return 1
      if (bIndex === undefined) return -1
      if (aIndex === bIndex) return 0

      return aIndex > bIndex ? 1 : -1
    })
  }

  public filterFilters(
    str: string,
    excludeValues: string[],
    fieldName: FilterServiceNS.KeysFilterName = "label"
  ) {
    const set = new Set(
      excludeValues.map((value) =>
        value[0] === INEQUALITY_SYMBOL ? value.slice(1) : value
      )
    )
    let value = str.toLowerCase()

    return this.filters.filter((filter) => {
      if (filter.type === "query") return false
      if (filter.readOnly) return false

      const inequality = value[0] === INEQUALITY_SYMBOL

      if (inequality) {
        if (!filter.linkedKey) return false
      }

      const compareValue = inequality ? value.slice(1) : value

      return (
        (!set.has(filter[fieldName]) &&
          filter[fieldName].indexOf(compareValue) !== -1) ||
        filter[fieldName] === str
      )
    })
  }

  public getTextInSmartSearchFormat(searchParams: URLSearchParams) {
    const keys: string[] = []

    searchParams.forEach((_, key) => {
      keys.push(key)
    })

    const charAtEndOfTheLine = "\u00A0" // char needed to start with new tag

    return (
      this.getFilterInOrder(keys, "key").reduce((acc, filter) => {
        if (filter.value) {
          acc += `${acc.length ? "\u00A0" : ""}${filter.label}:${filter.value}`
        }

        return acc
      }, "") + charAtEndOfTheLine
    )
  }

  public freeSearchAvailable() {
    return this.filters.some((filter) => filter.type === "query")
  }
}
