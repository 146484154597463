import { useEffect, useRef, useState } from "react"

import { Filter } from "@/organisms/FilterNew/Filter"
import { KodemScoreFilter } from "@/organisms/FilterNew/KodemScoreFilter"
import { InputSmartSearchLoading, SmartSearch } from "@/organisms/SmartSearch"
import { useFiltersTriage } from "@/pages/TriagePage/application/useTriage"
import * as filterActions from "./getFilters"

import type { IssuesFilterComponent } from "./IssuesFilter.types"

export const IssuesFilter: IssuesFilterComponent = (props) => {
  const initialState = useRef(filterActions.getInitialValues(props)).current
  const [filters, setFilters] = useState({
    loading: true,
    values: filterActions.getFilters(props)
  })
  const { t } = props
  const context = useFiltersTriage()
  const isFilterLoading = context[1]

  useEffect(() => {
    if (!isFilterLoading) {
      const owaspFilter = context[0].response?.allFilters.find(
        ({ key }) => key === "owasp"
      )
      const labelFilter = context[0].response?.allFilters.find(
        ({ key }) => key === "label"
      )

      const values = filters.values.map((filter) => {
        if (filter.key === "owasp" && owaspFilter) return owaspFilter
        if (filter.key !== "label" && filter.key !== "notLabel") return filter

        return {
          ...filter,
          options: labelFilter?.options
        }
      })

      setFilters({ ...filters, loading: false, values: values })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilterLoading])

  return (
    <Filter.Provider
      initialState={initialState}
      useQueryString={false}
      onChange={props.onChange}
      isLoading={filters.loading}
    >
      <Filter.Template
        isLoading={filters.loading}
        search={
          filters.loading ? (
            <InputSmartSearchLoading />
          ) : (
            <SmartSearch
              id="IssueFilterCodeRepositoriesDrawer"
              filters={filters.values}
            />
          )
        }
      >
        <Filter.Checkbox
          order="none"
          keyFilter="fixability"
          items={filterActions.getFixabilityOptions(t)}
          placeHolderDefaultValue="Fixability"
          isLoading={filters.loading}
        />
        <Filter.Checkbox
          order="none"
          keyFilter="severity"
          items={filterActions.SEVERITIES_FILTER_OPTIONS}
          placeHolderDefaultValue="Severity"
          isLoading={filters.loading}
        />
        <Filter.Insights filters={filters.values} isLoading={isFilterLoading} />
        <KodemScoreFilter
          maxValue={1000}
          placeHolderDefaultValue="Score"
          prefix="Score ≥"
          isLoading={filters.loading}
        />
      </Filter.Template>
    </Filter.Provider>
  )
}
