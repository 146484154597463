import {
  EXPLOITABILITY_FILTER_OPTIONS,
  getFixabilityOptions,
  SEVERITIES_FILTER_OPTIONS
} from "@/const/options.constants"
import { getDismissReasonFilter } from "@/helpers/filters.helper"
import { INEQUALITY_SYMBOL } from "@/organisms/SmartSearch/const/symbol.constants"
import {
  getCVEFilter,
  getSeverityFilter
} from "@/pages/InventoryPage/utils/filters.utils"

import type { TFunction } from "@/hooks/useTranslation"
import type { Filters } from "@/organisms/SmartSearch/types/options.types"

export {
  EXPLOITABILITY_FILTER_OPTIONS,
  getFixabilityOptions,
  SEVERITIES_FILTER_OPTIONS
}

export const getInitialValues = (props: { params: Record<string, any> }) =>
  props.params

export const getFilters = (props: {
  t: TFunction
  includeApplicationFilter?: boolean
}): Filters => {
  const { t } = props

  const filters = [
    {
      key: "packageName",
      label: t("filters.package"),
      type: "input",
      helper: [{ description: "Matches package names as a substring" }]
    },
    getCVEFilter(),
    {
      key: "fixability",
      label: t("filters.fixability"),
      type: "multiple",
      options: getFixabilityOptions(t)
    },
    getSeverityFilter(),
    {
      key: "runtime",
      label: t("filters.runtime"),
      type: "single",
      order: 1,
      options: [
        { label: "Yes", value: "YES" },
        { label: "No", value: "NO", disabled: true },
        { label: "N/A", value: "UNDETERMINED", disabled: true }
      ],
      helper: [{ description: "Packages that are seen in runtime" }]
    },
    {
      key: "ingress",
      label: t("filters.ingress"),
      type: "single",
      order: 2,
      options: [
        { label: "Yes", value: "YES" },
        { label: "No", value: "NO" },
        { label: "N/A", value: "UNDETERMINED", disabled: true }
      ]
    },
    {
      key: "exploitMaturity",
      label: t("filters.exploitability"),
      type: "multiple",
      options: EXPLOITABILITY_FILTER_OPTIONS
    },
    {
      key: "cweId",
      label: t("filters.cweId").toLowerCase(),
      displayLabel: t("filters.cweId"),
      type: "input",
      helper: [
        {
          label: "CWE-id: value1, value2",
          description: "Matches multiple cwe identifiers (e.g. cwe-506)"
        }
      ]
    },
    {
      key: "cweDescription",
      label: t("filters.cweName").toLowerCase(),
      displayLabel: t("filters.cweName"),
      type: "input",
      helper: [
        {
          label: "CWE-name: value1, value2",
          description: 'Matches multiple cwe name (e.g. "Prototype Pollution")'
        }
      ]
    },
    {
      key: "environment",
      label: t("filters.environment"),
      type: "input",
      helper: [
        {
          label: "environment:value1,value2",
          description: "Equals value1 OR value2"
        }
      ]
    },
    {
      key: "namespace",
      label: t("filters.namespace"),
      type: "input",
      helper: [
        {
          label: "namespace:value1,value2",
          description: "Matches namespaces as a substring (e.g. users,system)"
        }
      ]
    },
    {
      key: "issueStatus",
      label: t("filters.issueStatus"),
      type: "multiple",
      helper: [
        {
          label: "status:value1,value2",
          description: "Equals value1 OR value2"
        }
      ],
      options: [
        { label: "Open", value: "open" },
        { label: "Dismissed", value: "dismissed" }
      ]
    },
    {
      key: "kodemScore",
      label: t("filters.kodemScore"),
      type: "inputNumber",
      validation: (value: number) =>
        !isNaN(value) && value >= 0 && value <= 1000,
      errorMessage: "Insert value between 0 to 1000",
      helper: [
        { description: "Is greater or equal to a number between 0 - 1000" }
      ]
    },
    {
      key: "fromBaseImage",
      label: t("filters.fromBaseImage"),
      order: 6,
      type: "single",
      options: [
        { label: "Yes", value: "YES" },
        { label: "No", value: "NO" },
        { label: "N/A", value: "UNDETERMINED", disabled: true }
      ]
    },
    {
      key: "isDirect",
      label: t("filters.isDirect"),
      order: 4,
      type: "single",
      options: [
        { label: "Yes", value: "YES" },
        { label: "No", value: "NO" },
        { label: "N/A", value: "UNDETERMINED", disabled: true }
      ]
    },
    {
      key: "isIndirect",
      label: t("filters.isIndirect"),
      order: 5,
      type: "single",
      options: [
        { label: "Yes", value: "YES" },
        { label: "No", value: "NO" },
        { label: "N/A", value: "UNDETERMINED", disabled: true }
      ]
    },
    {
      key: "introducedThrough",
      label: t("filters.introducedThrough"),
      type: "input",
      linkedKey: "introducedThrough",
      helper: [
        {
          label: "introduced-through:value1,value2",
          description:
            "Matches introduced through paths which include the provided substrings. Accepts package names, base images and code weakness locations"
        }
      ]
    },
    {
      key: "notIntroducedThrough",
      label: `${INEQUALITY_SYMBOL}${t("filters.introducedThrough")}`,
      type: "input",
      linkedKey: "introducedThrough",
      inequality: true,
      helper: [
        {
          label: "!introduced-through:value1,value2",
          description:
            "Matches introduced through paths which does NOT include the provided substrings. Accepts package names, base images and code weakness locations"
        }
      ]
    },
    {
      key: "label",
      label: "labels",
      linkedKey: "label",
      type: "multiple",
      helper: [
        {
          label: "Label:value1, value2",
          description: "Equals value1 OR value2"
        }
      ],
      options: []
    },
    {
      key: "notLabel",
      label: `${INEQUALITY_SYMBOL}labels`,
      type: "multiple",
      linkedKey: "label",
      inequality: true,
      helper: [
        {
          label: "!Label:value1, value2",
          description: "Not equals value1 OR value2"
        }
      ],
      options: []
    },
    {
      key: "hasLabel",
      label: "has-label",
      type: "single",
      options: [
        { label: "Yes", value: "YES" },
        { label: "No", value: "NO" }
      ]
    },
    {
      key: "hasAction",
      label: t("filters.hasAction"),
      order: 4,
      type: "single",
      options: [
        { label: "Yes", value: "YES" },
        { label: "No", value: "NO" }
      ]
    },
    {
      key: "jiraIssueId",
      label: t("filters.jiraIssueId").toLowerCase(),
      displayLabel: t("filters.jiraIssueId"),
      type: "input",
      helper: [
        {
          label: "Jira-Issue-ID:value1, value2",
          description: "Matches external IDs as substring (e.g. kod-11, kod23)"
        }
      ]
    },
    getDismissReasonFilter(t)
  ] as Filters
  if (props.includeApplicationFilter) {
    filters.push({
      key: "application",
      label: t("filters.application"),
      type: "input",
      helper: [
        {
          label: t("filters.helpers.application.label"),
          description: t("filters.helpers.application.description")
        }
      ]
    })
  }
  return filters
}
